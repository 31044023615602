import React, { useState } from "react";
import Layout from "../components/layout";
import InfoBox from "../components/InfoBox";
import PageHeader from "../components/PageHeader/PageHeader";
import { PageContainer, PageContent, Section, SectionForm } from "../styles";
import styled from "styled-components";
import Footer from "../components/Footer";
import Helmet from "react-helmet";

export default function Contact() {
  const [preferredContactType, setPreferredContactType] = useState("");
  function renderPrefferedContactField() {
    return (
      <>
        <input
          placeholder="email"
          name="email"
          type="email"
          className="form-control"
          id="emailAddress"
          required={preferredContactType === "email"}
        ></input>
        <input
          placeholder="phone"
          name="phone"
          type="text"
          className="form-control"
          id="phone"
          required={preferredContactType === "phone"}
        ></input>
      </>
    );
  }
  return (
    <Layout>
      <Helmet>
        <html lang="en" />
        <title>Contact - Peace By Piece CBT</title>
        <meta
          name="description"
          content="I like to work with you as an individual, not with you as a label,
            so that you are looking at yourself as a unique person - not a
            diagnosis."
        />
      </Helmet>
      <PageContainer>
        <PageHeader title="Contact" description="Get in touch" />

        <PageContent>
          <div className="container">
            <div className="row">
              <div className="col col-lg-6">
                <Section>
                  <InfoBox>
                    <p>
                      I can be contacted using the contact form on this page. I
                      will usually reply within 24 hours.
                    </p>
                  </InfoBox>
                </Section>
              </div>
              <div className="col col-lg-6">
                <Section>
                  <SectionForm
                    id="contactForm"
                    name="contact"
                    method="post"
                    action="/success"
                    data-netlify="true"
                    netlify-honeypot="bot-field"
                  >
                    <p style={{ display: "none" }}>
                      <label>
                        Don’t fill this out if you’re human:{" "}
                        <input name="bot-field" />
                      </label>
                    </p>
                    <input type="hidden" name="form-name" value="contact" />
                    <input type="hidden" name="email" value="" />
                    <input type="hidden" name="phone" value="" />

                    <fieldset className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        required
                      ></input>
                    </fieldset>
                    <fieldset>
                      <select
                        style={{ background: "#ffffff", color: "#000000" }}
                        defaultValue="Preferred Contact Method"
                        className="form-control"
                        placeholder="Preferred method of contact:"
                        name="contactType"
                        onChange={(e) =>
                          setPreferredContactType(e.target.value)
                        }
                      >
                        <option disabled={true}>
                          Preferred Contact Method
                        </option>
                        <option value="phone">Phone</option>
                        <option value="email">Email</option>
                      </select>
                    </fieldset>
                    <fieldset>{renderPrefferedContactField()}</fieldset>
                    <fieldset>
                      <label htmlFor="notes">Enquiry Details</label>
                      <textarea
                        name="notes"
                        className="form-control"
                        id="notes"
                        required
                      />
                    </fieldset>
                    <ContactFooter>
                      <button type="submit">Send Enquiry</button>
                    </ContactFooter>
                  </SectionForm>
                </Section>
              </div>
            </div>
          </div>
          <div style={{ background: "rgb(30, 36, 54)" }}>
            <Footer contactForm={false} />
          </div>
        </PageContent>
      </PageContainer>
    </Layout>
  );
}

const ContactFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 0 1rem 0;
`;
